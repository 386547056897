import axios from 'axios'

class AuthService {
    login(username, password) {
        return axios.post('/api/ivy-auth/v1/auth/login', {
            username,
            password
        }, {
            withCredentials: true
        })
    }
    logout() {
        return axios.post('/api/ivy-auth/v1/auth/logout', {}, {
            withCredentials: true
        })
    }
    isLogged() {
        try {
            return this.getJwt()
        }catch{
            return false
        }
    }
    isAdmin() {
        try {
            return this.getJwt().roles.includes('ADMIN')
        }catch{
            return false
        }
    }

    // Helpers
    getJwt() {
        const jwtCookie = document.cookie
            .split('; ')
            .find(row => row.startsWith('jwt='))

        if (jwtCookie) {
            const jwtValue = jwtCookie.split('=')[1]

            const decodedPayload = this.decodeJWT(jwtValue)
            return decodedPayload
        } else {
            throw new Error('Unable to find JWT')
        }
    }
    decodeJWT(token) {
        const parts = token.split('.')
        if (parts.length !== 3) {
            throw new Error('Invalid JWT format')
        }

        const payload = JSON.parse(atob(parts[1]))
        return payload
    }
}

const authSercvice = new AuthService()

export default authSercvice